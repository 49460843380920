#theme_swich label {
  vertical-align: 5px;
}

input[type="checkbox"] {
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  vertical-align: -5px;
}

input[type="checkbox"]:focus {
  outline: 0;
}

.toggle {
  background: linear-gradient(#2d2f39 0%, #1f2027 100%);
  border: 1px solid #474755;
  border-radius: 16px;
  width: 52px;
  height: 30px;
  margin: 0;
  transition: all .2s;
  display: inline-block;
  position: relative;
}

.toggle:after {
  content: "";
  background: #fff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  transition: all .2s cubic-bezier(.5, .1, .75, 1.35);
  position: absolute;
  top: 2px;
  left: 2px;
  box-shadow: 0 1px 2px #2c2c2c33;
}

.toggle {
  border-color: var(--menu-bar-border-color) !important;
}

.toggle:checked:after {
  transform: translateX(22px);
}

/*# sourceMappingURL=index.a119e1e5.css.map */
